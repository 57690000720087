import React from 'react';
import { Block, Layout } from '@dragonchain-dev/dragon-kit';
import { Layout as PageLayout, SEO, ContestRow } from '../../../components/_index';

const SkyColor = () => {
  return (
    <PageLayout>
      <SEO title="Sky Contest" noFollow />
      <Layout dark background="blue-dark-fade" header="Sky Color Contest">
        <Block>
          <ContestRow name="What is the color of the sky" winnerKey="emailHash" />
        </Block>
      </Layout>
    </PageLayout>
  );
};

export default SkyColor;
